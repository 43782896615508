<template>
    <div id="app">
        <div class="header">
            <div class="logo_img" @click="logoClick">
                <div style="display:flex; align-items: center; float:left; height: 65px;">
                    <div class="header-logo">
                        <img id="header-img" src="./assets/logo.jpeg">
                    </div>
                </div>
                <div style="display:flex; float:left; height: 65px;">
                    <div class="header-important">
                        <div class="company_name">重庆小智职来</div>
                        <div class="header-title company_name">www.xzzl.xyz</div>
                    </div>
                </div>
            </div>
            <div id="menu_index">
                <!-- web端菜单导航 -->
                <el-menu v-if="seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click"
                         text-color="#333" style="height: 65px; border:0;" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="1" class="header-title">首页</el-menu-item>
                    <el-menu-item index="2" class="header-title">公司简介</el-menu-item>
                    <el-menu-item index="3" class="header-title">app平台</el-menu-item>
<!--                    <el-menu-item index="4" class="header-title">微信公众号管理平台</el-menu-item>-->
                    <el-menu-item index="5" class="header-title">公司理念</el-menu-item>
                    <el-menu-item index="6" class="header-title">关于我们</el-menu-item>
                </el-menu>
                <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
                <el-menu v-if="!seen" :default-active="activeIndex" class="el-menu-demo" menu-trigger="click"
                         text-color="#333" style="height: 65px; border:0;" mode="horizontal"
                         @select="handleSelectMobile">
                    <el-sub-menu index="1" class="header-title mobile_menu">
                        <template #title>{{currentMenu}}</template>
                      <el-menu-item index="1" class="header-title">首页</el-menu-item>
                      <el-menu-item index="2" class="header-title">公司简介</el-menu-item>
                      <el-menu-item index="3" class="header-title">app平台</el-menu-item>
                      <!--                    <el-menu-item index="4" class="header-title">微信公众号管理平台</el-menu-item>-->
                      <el-menu-item index="5" class="header-title">公司理念</el-menu-item>
                      <el-menu-item index="6" class="header-title">关于我们</el-menu-item>
                    </el-sub-menu>
                </el-menu>
            </div>
        </div>
        <div class="container" style="clear: both;">
            <router-view></router-view>
        </div>

        <div style="width: 90%;margin: 20px auto;">
            <div style="height:1px;padding:0;background-color:#999;">
            </div>
        </div>
        <div style="width: 90%;margin: 20px auto;">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12">
                    <p class="footer_font">联系电话：18502395671</p>
                    <div style="margin: auto;" class="footer_font">
                        互联网ICP备案：
                        <a href="http://beian.miit.gov.cn/" target="_blank" >渝ICP备2024036656号-2</a>
                    </div>
                    <div style="margin: auto;" class="footer_font">
                      www.xzzl.xyz 重庆小智职来有限公司版权所有
                    </div>
                </el-col>
<!--                <el-col :xs="24" :sm="12">-->
<!--                    <div><img :src="company.qrcode" style="width: 100px;height: 100px;border: 0"/></div>-->
<!--                    <div class="footer_font">关注南京碧华商务公众号</div>-->
<!--                </el-col>-->
            </el-row>
        </div>
        <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
            <p style="font-size: 0.625em; font-weight: bold">TOP</p>
            <img src="./assets/other/launch.png" style="height: 45px;width: 35px"/>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue';
    export default {
        name: "App",
        setup() {
            let btn = ref(null); //本质是reactive({value:null})
            // 需要在生命周期获取
            //onMounted(()=>{
                // 当界面挂载出来后就会自动执行
               //console.log(btn.value);
            //});
            //接受的是null,原因是setup执行时机比mounted早,dom还没形成
            //console.log(btn.value);
            return { btn };
        },
        data() {
            return {
                activeIndex: '1',
                company: {
                    qrcode: require("../static/other/qrcode.jpg"),
                },
                scroll: '',
                timer: '',
                seen: true,
                currentMenu: '首页'
            };
        },
        methods: {
            handleSelect(key) {
                switch (key) {
                    case '1':
                        this.$router.push({name: "Index"});
                        break;
                    case '2':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#产品解决方案";
                        break;
                    case '3':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#微信商城平台";
                        break;
                    case '4':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#微信公众号管理平台";
                        break;
                    case '5':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#合作伙伴";
                        break;
                    case '6':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#关于我们";
                        break;
                }
            },
            handleSelectMobile(key) {
                switch (key) {
                    case '1':
                        this.$router.push({name: "Index"});
                        this.currentMenu = '首页';
                        break;
                    case '2':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#产品解决方案";
                        this.currentMenu = '首页';
                        break;
                    case '3':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#微信商城平台";
                        break;
                    case '4':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#微信公众号管理平台";
                        break;
                    case '5':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#合作伙伴";
                        this.currentMenu = '首页';
                        break;
                    case '6':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#关于我们";
                        break;
                }
            },
            handleScroll() {
                console.log(this.btn);
                this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
                if (this.scroll > 600) {
                    //判断滚动后高度超过400px,就显示
                    this.btn.style.display = 'block';
                } else {
                    this.btn.style.display = 'none'
                }
            },
            backTop() { //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
                this.timer = setInterval(() => {
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5)
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    if (osTop === 0) {
                        clearInterval(this.timer)
                    }
                }, 30)
            },
            menuSeen() {
                // 屏幕尺寸
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    this.seen = true;
                } else {
                    this.seen = false;
                }
            },
            logoClick() {
                this.$router.push({name: "Index"});
                this.currentMenu = '首页';
            }
        },
        mounted() {
            this.menuSeen()
            window.addEventListener('scroll', this.handleScroll)
        },
    }
</script>

<style scoped>
    .logo_img {
        float: left;
        width: 30%;
        cursor: pointer;
    }

    #back_to_top {
        position: fixed;
        bottom: 50px;
        right: 30px;
        cursor: pointer
    }

    .header-title {
        font-size: 1em;
    }

    .company_name {
        font-weight: normal;
        font-size: 1em;
    }

    .footer_font {
        font-size: 1em;
    }

    #menu_index {
        float: left;
        width: 70%;
        height: 100%
    }

    .header {
        margin: auto;
        width: 90%;
        height: 65px;
        /*background: #F56C6C;*/
    }

    @media screen and (max-device-width: 415px) {
        .logo_img {
            float: left;
            width: auto;
            cursor: pointer;
        }

        #back_to_top {
            position: fixed;
            bottom: 50px;
            right: 5px;
            cursor: pointer
        }

        .footer_font {
            font-size: 0.875em;
        }

        #menu_index {
            float: right;
            width: auto;
            height: 100%
        }

        .mobile_menu {

        }
    }

    #header-img {
        width: 50px;
        height: 50px;
        float: left;
        margin: auto;
    }

    .header-logo {
        height: 50px;
        width: 50px;
        align-self: center;
    }

    .header-important {
        float: left;
        margin-left: 6px;
        align-self: center;
    }

    .footer {
        display: flex;
        align-items: center;
        margin: 10px auto;
        width: 90%;
    }

    a {
        text-decoration: none;
    }

    a:visited {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    a:active {
        text-decoration: none;
    }
</style>
